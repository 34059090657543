import { Token } from '@uniswap/sdk-core'

import { WETH, Eth } from './native-token'

import { ChainId } from 'constants/chains'
import { MAINNET, TESTNET } from './periphery'

export { WETH, Eth }

export const ETH = Eth.onChain(ChainId.MAINNET)

export const USDC = {
  [ChainId.MAINNET]: new Token(
    ChainId.MAINNET,
    '0xbDeEbCC920B4788C5DD38DdAB90Bf8738e63ab2b',
    6,
    'baseUSDC',
    'Axelar Wrapped USDC'
  ),
  [ChainId.TESTNET]: new Token(
    ChainId.TESTNET,
    '0x6ae1c78CaCB230F904cDc85E74454aDa0d53B807',
    6,
    'DexBased USDC',
    'Axelar Wrapped USDC'
  ),
}


export const DIFFUSION = makeToken('DexBased', 'DexBased', 18, {
  [ChainId.MAINNET]: MAINNET.diffusion,
  [ChainId.TESTNET]: TESTNET.diffusion || '0x6ae1c78CaCB230F904cDc85E74454aDa0d53B807',
})

export const XDIFFUSION = makeToken('xBased', 'XBASED', 18, {
  [ChainId.MAINNET]: MAINNET.diffusionbar,
  [ChainId.TESTNET]: TESTNET.diffusionbar || '0x908e365F2b3e66a28CE4009Dc2F56E75e59B3Ea5 ',
})

function makeToken(name: string, symbol: string, decimals: number, addresses: Record<ChainId, string>) {
  return {
    [ChainId.MAINNET]: new Token(ChainId.MAINNET, addresses[ChainId.MAINNET], decimals, symbol, name),
    [ChainId.TESTNET]: new Token(ChainId.TESTNET, addresses[ChainId.TESTNET], decimals,  symbol, name),
  }
}

