/**
 *
 * https://github.com/diffusion-fi/v2-periphery/blob/main/scripts/config/config.ts
 *
 * When changing this also update: cypress/integration/contracts.ts
 *
 */

const PERIPHERY_TESTNET = {
  factory: '0xCc06303c4Ba41112Eb4E0f36A2489c88819BBe23',
  weth9: '0x4200000000000000000000000000000000000006',
  router: '0x9EB68BF5d0aa73d4393D6854De80B7F25DE4f89F',
  mockUSDC: '0xbDeEbCC920B4788C5DD38DdAB90Bf8738e63ab2b',
  mockCANTO: '0x0000000000000000000000000000000000000000',
  mockATOM: '0x0000000000000000000000000000000000000000',
  mockOSMOSIS: '0x0000000000000000000000000000000000000000',
  multicall2: '0xCaEFc8Be14a0799dc98bF39Fd5Fbd5D3a6F95Cd5',
  LHS: '0x0000000000000000000000000000000000000000',
  RHS: '0x0000000000000000000000000000000000000000',
  testerAddress: '0x0000000000000000000000000000000000000000',
  //0x851e5cE9fa409B731f980a5E00FA889b58D9037D
  // 0xA2c659531B15bFf2556Ea7E12D477D3C8761ACD9
  //0x95BF964f113a75a3974E8164105e6e5A8D743b87
  // 0x62154D72C202f04CA50a3Ba5630052D0348f337A
  rewardToken: '0x6ae1c78CaCB230F904cDc85E74454aDa0d53B807',
  secondaryRewardToken: '0x908e365F2b3e66a28CE4009Dc2F56E75e59B3Ea5',
  miniChef: '0x1e0c642860115deFd5a3FFC9b055C9040746FfaA',
  complexRewarderTime: '0x0000000000000000000000000000000000000000',
  diffusion: '0x6ae1c78CaCB230F904cDc85E74454aDa0d53B807',
}

// const TESTNET_STABLE_PAIRS: string[] = [
//   '0x252631e22e1ECc2fc0E811562605ed624B7E31d5', // NOTE/USDT
//   '0x2db30A39Ec88247da8906506DB8E9dd933A5C775', // NOTE/USDC
// ]

const MAINNET_PERIPHERY = {
  factory: '0xCc06303c4Ba41112Eb4E0f36A2489c88819BBe23',
  weth9: '0x4200000000000000000000000000000000000006',
  router: '0x9EB68BF5d0aa73d4393D6854De80B7F25DE4f89F',
  multicall2: '0xCaEFc8Be14a0799dc98bF39Fd5Fbd5D3a6F95Cd5',
  //
  minichef: '0x0000000000000000000000000000000000000000',
  diffusion: '0x0000000000000000000000000000000000000000',
}

export const MAINNET = {
  ...MAINNET_PERIPHERY,
  diffusionbar: '0x0000000000000000000000000000000000000000',
  airdrop: '0x0000000000000000000000000000000000000000',
}

export const TESTNET = {
  ...PERIPHERY_TESTNET,
  airdrop: '0x6ae1c78CaCB230F904cDc85E74454aDa0d53B807',
  diffusionbar: '0x908e365F2b3e66a28CE4009Dc2F56E75e59B3Ea5',
}
