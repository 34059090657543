import React, { useState } from 'react';
import Modal from 'components/Modal';
import styled from 'styled-components/macro';
import { AutoColumn } from 'components/Column';
import { RowBetween } from 'components/Row';
// import { TYPE } from 'theme';
import { ButtonPrimary, ButtonEmpty } from 'components/Button';

const LS_KEY = 'diff:disclaimer-accepted';

export function DisclaimerModal() {
  const [isOpen, setIsOpen] = useState(true);
  const [isTOSOpen, setIsTOSOpen] = useState(false);

  if (!isOpen) {
    return null;
  }

  return (
    <>
      <Modal
        isOpen={isTOSOpen}
        onDismiss={() => setIsTOSOpen(false)}
 
        css={`max-width: 950px;`}
      >
         <CloseButton onClick={() => setIsTOSOpen(false)}>X</CloseButton>
        <p>
          LitSwap is a decentralized peer-to-peer protocol that people can use to create liquidity and trade
          ERC-20 tokens on BASE. This website is a public and free interface to help users interact with the
          decentralized DEX smart contracts that are deployed on the Base Blockchain. Your use of the LitSwap
          protocol involves various risks, including, but not limited to, losses while digital assets are being
          supplied to the LitSwap protocol and losses due to the fluctuation of prices of tokens in a trading
          pair or liquidity pool. Before using the LitSwap protocol, you should review the relevant documentation
          to make sure you understand how the LitSwap protocol works. Additionally, just as you can access email
          protocols such as SMTP through multiple email clients, you can access the LitSwap protocol through
          dozens of web or mobile interfaces. You are responsible for doing your own diligence on those interfaces
          to understand the fees and risks they present.
        </p>
        <p>
          The LitSwap PROTOCOL IS PROVIDED AS IS, AT YOUR OWN RISK, AND WITHOUT WARRANTIES OF ANY
          KIND. The LitSwap protocol is not owned by anyone, it is run by smart contracts deployed on the Base
          blockchain. No developer or entity involved in creating the LitSwap protocol will be liable for any
          claims or damages whatsoever associated with your use, inability to use, or your interaction with other
          users of, the LitSwap protocol, including any direct, indirect, incidental, special, exemplary,
          punitive or consequential damages, or loss of profits, cryptocurrencies, tokens, or anything else of
          value. The LitSwap protocol is not a financial product and should not be treated as such.
        </p>
 
      </Modal>

      <Modal
        isOpen
        onDismiss={() => {
          // Intentionally left blank because we don't want to allow dismissing in some cases
        }}
        maxHeight={90}
        css={`max-width: 550px;`}
      >
        <Wrapper>
          <ContentWrapper gap="lg">
            <RowBetween>
              <div css={`font-size: 12px;`}>
              <h1> LitSwap unique features: </h1>
              
              <h2> Time lock when removing liquidity for 24 hours to prevent early rug pulls. </h2>
              
              <h2> Checks the total supply of the tokens to prevent secret minting and dumping in swaps.</h2>
              </div>
            </RowBetween>
            <RowBetween>
              <ButtonPrimary
                onClick={() => {
                  localStorage.setItem(LS_KEY, 'true');
                  setIsOpen(false);
                }}
              >
                Go to App!
              </ButtonPrimary>
            </RowBetween>
          </ContentWrapper>
          <ButtonEmpty onClick={() => setIsTOSOpen(true)}>
            Read TOS
          </ButtonEmpty>
        </Wrapper>
      </Modal>
    </>
  );
}

const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
`;


const ContentWrapper = styled(AutoColumn)`
  width: 100%;
  padding: 1rem;
`;

const Wrapper = styled.div`
  ${({ theme }) => theme.flexColumnNoWrap}
  margin: 0;
  padding: 0;
  width: 100%;
`;
